import { FilterDto, isNullOrWhitespace } from '@nuis/common';
import { DateRange } from '@nuis/forms';

export function toTextFilterDto(value: string | null): FilterDto<string>[] | null {
    return !isNullOrWhitespace(value) ? [{ value: value ?? '', matchMode: 'contains' }] : null;
}

export function toSingleSelectFilterDto<T = string>(value: T | null): FilterDto<T>[] | null {
    return value != null ? [{ value: value, matchMode: 'equals' }] : null;
}

export function toMultiSelectFilterDto<T = string>(value: T[] | null): FilterDto<T[]>[] | null {
    return value != null ? [{ value: value, matchMode: 'in' }] : null;
}

export function toDateRangeFilterDto(value: DateRange | null): FilterDto<string | null>[] | null {
    return value != null
        ? [
              { value: value?.start?.toISODate() ?? null, matchMode: 'dateAfter' },
              { value: value?.end?.toISODate() ?? null, matchMode: 'dateBefore' },
          ]
        : null;
}
